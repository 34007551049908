export const QUERY_KEYS = {
  GET_BILLING_KEY: 'billing',
  GET_BILLING_TASK_KEY: 'billing-task',
  GET_BILLING_INSTACAR_KEY: 'billing-instacar',
  GET_BILLING_INSTARIDE_KEY: 'billing-instaride',
  GET_BILLING_REFUNDS_KEY: 'billing-refunds',
  GET_BILLING_DAMAGES_KEY: 'billing-damages',
  GET_BILLING_OTHER_KEY: 'billing-other',
  GET_BILLING_ASSIGNEES_KEY: 'billing-assignees',
  GET_VEHICLE_BY_INTERNAL_ID_KEY: 'vehicle-by-internal-id',
  GET_DRIVERSE_CAMPAIGNS_BY_PARTNER_ID_KEY: 'driverse-campaigns-by-driverse-id',
  GET_DRIVERSE_CAMPAIGN_BY_ID_KEY: 'driverse-campaign-by-id',
  GET_DRIVERSE_COUPONS_BY_CAMPAIGN_ID_KEY: 'driverse-coupons-by-campaign-id',
  GET_ARNM_REPAIRS_TASK_KEY: 'arnmRepairs-task',
  GET_SERVICE_POINTS_KEY: 'service-points',
};
