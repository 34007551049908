import theme from 'config/theme';

export const ARNM_REPAIRS_TASK_STATUSES_NAMES = {
  0: {
    label: 'Open Cases',
    order: 0,
    styles: {
      backgroundColor: theme.palette.blues.babyBlue200,
      color: theme.palette.blues.babyBlue900,
    },
  },
  2: {
    label: 'In Progress',
    order: 1,
    styles: {
      backgroundColor: theme.palette.violets.violet200,
      color: theme.palette.violets.violet900,
    },
  },
  13: {
    label: 'Service Point',
    order: 2,
    styles: {
      backgroundColor: theme.palette.fukshias.fukshia200,
      color: theme.palette.fukshias.fukshia900,
    },
  },
  14: {
    label: 'Out Date',
    order: 3,
    styles: {
      backgroundColor: theme.palette.oranges.orange200,
      color: theme.palette.oranges.orange900,
    },
  },
  15: {
    label: 'Billing',
    order: 4,
    styles: {
      backgroundColor: theme.palette.yellows.yellow200,
      color: theme.palette.yellows.yellow900,
    },
  },
  3: {
    label: 'Done',
    order: 5,
    styles: {
      backgroundColor: theme.palette.greens.green200,
      color: theme.palette.greens.green900,
    },
  },
  12: {
    label: 'Cancelled',
    order: 6,
    styles: {
      backgroundColor: theme.palette.reds.red200,
      color: theme.palette.reds.red900,
    },
  },
};

// TODO: update the custom fields
export const ARNM_REPAIRS_TASK_FIELD_NAMES = {
  assignee: 'Assignee',
  completionDate: 'Completion Date',
  roadsideAssistance: 'Roadside Assistance',
  chargeClientAmount: 'Charge Client Amount',
  outDateFromServicePoint: 'Out Date from Service Point',
  dateToServicePoint: 'Date to Service Point',
  emailSent: 'Email Sent',
  expectedOutFromServicePoint: 'Expected Out from Service Point',
  instadelivery: 'Instadelivery',
  invoiceDate: 'Invoice Date',
  noCostForInstacar: 'No cost for instacar',
  offer: 'Offer',
  dateOfRoadsideAssistance: 'Date of Roadside Assistance',
  servicePoint: 'Service Point',
  temporaryCarId: 'Temporary Car ID',
  doWeNeedATempCar: 'Do we need a Temp Car?',
  totalInvoiceCost: 'Total Invoice Cost',
  workOrder: 'Work Order',
  workorderid: 'Work Order ID',
  worktype: 'Work Type',
  details: 'Details',
  sideone: 'Side 1',
  sidetwo: 'Side 2',
  offeramount: 'Offer Amount',
  chargeclientamount: 'Charge Client Amount',
  status: 'Status',
  kilometers: 'Kilometers',
};

export const ARNM_REASONS_REPAIRS = [
  {
    text: 'Repair',
    value: 'RE02',
  },
];

export const ARNM_REASONS_REPAIRS_MAP = {
  RE02: 'Repair',
};

export const ARNM_REPAIRS_WORK_TYPES_DETAILS = [
  {
    text: 'Βαφή',
    value: 'Βαφή',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 2 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 2 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 2 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 2 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 2 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 2 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 2 },
      { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 2 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 2 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 2 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 2 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 2 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 2 },
      { text: 'ΦΑΣΑ', value: 'ΦΑΣΑ', repairDuration: 2 },
      { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 2 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Επισκευή',
    value: 'Επισκευή',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 6 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 6 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 6 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 6 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 6 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 6 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 6 },
      { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 6 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 6 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 6 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 6 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 6 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 6 },
      { text: 'ΠΑΡΑΘΥΡΟ', value: 'ΠΑΡΑΘΥΡΟ', repairDuration: 2 },
      { text: 'ΤΖΑΜΑΚΙ', value: 'ΤΖΑΜΑΚΙ', repairDuration: 2 },
      { text: 'ΠΑΡΜΠΡΙΖ', value: 'ΠΑΡΜΠΡΙΖ', repairDuration: 2 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Καύσιμο',
    value: 'Καύσιμο',
    details: [
      { text: 'ΧΛΜ ΕΚΤΟΣ ΟΡΙΟΥ', value: 'ΧΛΜ ΕΚΤΟΣ ΟΡΙΟΥ', repairDuration: 0 },
      { text: 'ΧΡΕΩΣΗ ΚΑΥΣΙΜΟΥ', value: 'ΧΡΕΩΣΗ ΚΑΥΣΙΜΟΥ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Πλύσιμο',
    value: 'Πλύσιμο',
    details: [
      { text: 'ΠΛΥΣΙΜΟ ΑΠΛΟ', value: 'ΠΛΥΣΙΜΟ ΑΠΛΟ', repairDuration: 2 },
      {
        text: 'ΒΙΟΛΟΓΙΚΟΣ ΚΑΘΑΡΙΣΜΟΣ',
        value: 'ΒΙΟΛΟΓΙΚΟΣ ΚΑΘΑΡΙΣΜΟΣ',
        repairDuration: 2,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Ανταλλακτικό',
    value: 'Ανταλλακτικό',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 15 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 15 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 15 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 15 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 15 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 15 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 15 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 15 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 15 },
      { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 15 },
      { text: 'ΨΑΛΙΔΙ', value: 'ΨΑΛΙΔΙ', repairDuration: 15 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 15 },
      { text: 'ΤΑΠΕΤΣΑΡΙΑ', value: 'ΤΑΠΕΤΣΑΡΙΑ', repairDuration: 15 },
      { text: 'ΚΑΘΙΣΜΑ', value: 'ΚΑΘΙΣΜΑ', repairDuration: 15 },
      { text: 'ΦΑΝΑΡI / ΦΩΣ', value: 'ΦΑΝΑΡI / ΦΩΣ', repairDuration: 15 },
      { text: 'ΑΜΟΡΤΙΣΕΡ', value: 'ΑΜΟΡΤΙΣΕΡ', repairDuration: 5 },
      { text: 'ΑΞΟΝΑΣ', value: 'ΑΞΟΝΑΣ', repairDuration: 15 },
      { text: 'ΜΠΑΡΑ', value: 'ΜΠΑΡΑ', repairDuration: 15 },
      { text: 'ΡΟΥΛΕΜΑΝ', value: 'ΡΟΥΛΕΜΑΝ', repairDuration: 5 },
      { text: 'ΦΡΕΝΟ', value: 'ΦΡΕΝΟ', repairDuration: 5 },
      { text: 'ΜΙΖΑ', value: 'ΜΙΖΑ', repairDuration: 15 },
      { text: 'ΑΙΣΘΗΤΗΡΑΣ', value: 'ΑΙΣΘΗΤΗΡΑΣ', repairDuration: 5 },
      { text: 'ΨΕΚΑΣΜΟΣ', value: 'ΨΕΚΑΣΜΟΣ', repairDuration: 15 },
      { text: 'ΥΑΛΟΚ/ΡΕΣ', value: 'ΥΑΛΟΚ/ΡΕΣ', repairDuration: 0 },
      { text: 'AIRBAG', value: 'AIRBAG', repairDuration: 15 },
      { text: 'ΖΩΝΗ', value: 'ΖΩΝΗ', repairDuration: 5 },
      { text: 'ΨΥΓΕΙΟ', value: 'ΨΥΓΕΙΟ', repairDuration: 15 },
      { text: 'ΚΟΡΝΑ', value: 'ΚΟΡΝΑ', repairDuration: 15 },
      { text: 'ΣΑΖΜΑΝ', value: 'ΣΑΖΜΑΝ', repairDuration: 15 },
      { text: 'ΦΙΛΤΡΟ', value: 'ΦΙΛΤΡΟ', repairDuration: 2 },
      { text: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', value: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', repairDuration: 15 },
      { text: 'ΕΓΚΕΦΑΛΟΣ', value: 'ΕΓΚΕΦΑΛΟΣ', repairDuration: 15 },
      { text: 'ΤΑΚΑΚΙΑ', value: 'ΤΑΚΑΚΙΑ', repairDuration: 2 },
      { text: 'ΜΠΟΥΖΙ', value: 'ΜΠΟΥΖΙ', repairDuration: 15 },
      { text: 'ΔΙΣΚΟΠΛΑΚΕΣ', value: 'ΔΙΣΚΟΠΛΑΚΕΣ', repairDuration: 2 },
      { text: 'ΤΑΠΑ ΚΑΡΤΕΡ', value: 'ΤΑΠΑ ΚΑΡΤΕΡ', repairDuration: 2 },
      { text: 'ΙΜΑΝΤΑΣ', value: 'ΙΜΑΝΤΑΣ', repairDuration: 2 },
      { text: 'ΜΠΑΤΑΡΙΑ', value: 'ΜΠΑΤΑΡΙΑ', repairDuration: 2 },
      { text: 'ΣΥΜΠΛΕΚΤΗΣ', value: 'ΣΥΜΠΛΕΚΤΗΣ', repairDuration: 15 },
      { text: 'ΒΟΛΑΝ', value: 'ΒΟΛΑΝ', repairDuration: 15 },
      { text: 'ΑΝΤΛΙΑ', value: 'ΑΝΤΛΙΑ', repairDuration: 15 },
      { text: 'ΚΑΛΟΡΙΦΕΡ', value: 'ΚΑΛΟΡΙΦΕΡ', repairDuration: 15 },
      { text: 'ΤΣΙΜΟΥΧΑ', value: 'ΤΣΙΜΟΥΧΑ', repairDuration: 2 },
      { text: 'ΕΛΑΤΗΡΙΟ', value: 'ΕΛΑΤΗΡΙΟ', repairDuration: 2 },
      { text: 'ΒΟΛΑΝ', value: 'ΒΟΛΑΝ', repairDuration: 15 },
      { text: 'ΒΑΛΒΙΔΑ', value: 'ΒΑΛΒΙΔΑ', repairDuration: 2 },
      { text: 'ΕΤΑΖΕΡΑ', value: 'ΕΤΑΖΕΡΑ', repairDuration: 2 },
      { text: 'ΚΕΡΑΙΑ', value: 'ΚΕΡΑΙΑ', repairDuration: 2 },
      { text: 'ΠΑΡΑΘΥΡΟ', value: 'ΠΑΡΑΘΥΡΟ', repairDuration: 2 },
      { text: 'ΤΖΑΜΑΚΙ', value: 'ΤΖΑΜΑΚΙ', repairDuration: 2 },
      { text: 'ΠΑΡΜΠΡΙΖ', value: 'ΠΑΡΜΠΡΙΖ', repairDuration: 2 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Εργασία',
    value: 'Εργασία',
    details: [
      {
        text: 'ΕΡΓΑΣΙΑ ΜΗΧΑΝΟΛΟΓΙΚΗ',
        value: 'ΕΡΓΑΣΙΑ ΜΗΧΑΝΟΛΟΓΙΚΗ',
        repairDuration: 1,
      },
      {
        text: 'ΕΡΓΑΣΙΑ ΗΛΕΚΤΡΟΛΟΓΙΚΗ',
        value: 'ΕΡΓΑΣΙΑ ΗΛΕΚΤΡΟΛΟΓΙΚΗ',
        repairDuration: 1,
      },
      {
        text: 'ΕΡΓΑΣΙΑ ΦΑΝΟΠΟΙΙΑΣ',
        value: 'ΕΡΓΑΣΙΑ ΦΑΝΟΠΟΙΙΑΣ',
        repairDuration: 1,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Έκδοση Κλειδιού',
    value: 'Έκδοση Κλειδιού',
    details: [{ text: 'ΜΕ ΧΡΕΩΣΗ', value: 'ΜΕ ΧΡΕΩΣΗ', repairDuration: 0 }],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Απαλλαγή',
    value: 'Απαλλαγή',
    details: [], // empty
  },
  {
    text: 'Έλεγχος / Malfunction',
    value: 'Έλεγχος / Malfunction',
    details: [
      { text: 'ΜΗΧΑΝΟΛΟΓΙΚΟΣ', value: 'ΜΗΧΑΝΟΛΟΓΙΚΟΣ', repairDuration: 5 },
      { text: 'ΗΛΕΚΤΡΟΛΟΓΙΚΟΣ', value: 'ΗΛΕΚΤΡΟΛΟΓΙΚΟΣ', repairDuration: 5 },
      { text: 'ΦΑΝΟΠΟΙΙΑΣ', value: 'ΦΑΝΟΠΟΙΙΑΣ', repairDuration: 5 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Ευθυγράμμιση',
    value: 'Ευθυγράμμιση',
    details: [
      { text: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', value: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Ασύμφορο',
    value: 'Ασύμφορο',
    details: [
      { text: 'ΟΛΙΚΗ ΚΑΤΑΣΤΡΟΦΗ', value: 'ΟΛΙΚΗ ΚΑΤΑΣΤΡΟΦΗ' },
      { text: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΕΜΠΡΟΣ', value: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΕΜΠΡΟΣ' },
      { text: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΠΙΣΩ', value: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΠΙΣΩ' },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Επισκευή από Πελάτη',
    value: 'Επισκευή από Πελάτη',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 0 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 0 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 0 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 0 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 0 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 0 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 0 },
      { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 0 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 0 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 0 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 0 },
      { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 0 },
      { text: 'ΨΑΛΙΔΙ', value: 'ΨΑΛΙΔΙ', repairDuration: 0 },
      { text: 'ΚΑΘΙΣΜΑΤΑ', value: 'ΚΑΘΙΣΜΑΤΑ', repairDuration: 0 },
      {
        text: 'ΛΟΙΠΕΣ ΕΠΙΣΚΕΥΕΣ',
        value: 'ΛΟΙΠΕΣ ΕΠΙΣΚΕΥΕΣ',
        repairDuration: 0,
      },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 0 },
      { text: 'ΤΑΠΕΤΣΑΡΙΑ', value: 'ΤΑΠΕΤΣΑΡΙΑ', repairDuration: 0 },
      { text: 'ΦΑΝΑΡI / ΦΩΣ', value: 'ΦΑΝΑΡI / ΦΩΣ', repairDuration: 0 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 0 },
      { text: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', value: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', repairDuration: 0 },
      { text: 'ΑΜΟΡΤΙΣΕΡ', value: 'ΑΜΟΡΤΙΣΕΡ', repairDuration: 0 },
      { text: 'ΑΞΟΝΑΣ', value: 'ΑΞΟΝΑΣ', repairDuration: 0 },
      { text: 'ΜΠΑΡΑ', value: 'ΜΠΑΡΑ', repairDuration: 0 },
      { text: 'ΡΟΥΛΕΜΑΝ', value: 'ΡΟΥΛΕΜΑΝ', repairDuration: 0 },
      { text: 'ΦΡΕΝΟ', value: 'ΦΡΕΝΟ', repairDuration: 0 },
      { text: 'ΜΙΖΑ', value: 'ΜΙΖΑ', repairDuration: 0 },
      { text: 'ΑΙΣΘΗΤΗΡΑΣ', value: 'ΑΙΣΘΗΤΗΡΑΣ', repairDuration: 0 },
      { text: 'ΨΕΚΑΣΜΟΣ', value: 'ΨΕΚΑΣΜΟΣ', repairDuration: 0 },
      { text: 'FREON', value: 'FREON', repairDuration: 0 },
      { text: 'ΥΑΛΟΚ/ΡΕΣ', value: 'ΥΑΛΟΚ/ΡΕΣ', repairDuration: 0 },
      { text: 'AIRBAG', value: 'AIRBAG', repairDuration: 0 },
      { text: 'ΖΩΝΗ', value: 'ΖΩΝΗ', repairDuration: 0 },
      { text: 'ΨΥΓΕΙΟ', value: 'ΨΥΓΕΙΟ', repairDuration: 0 },
      { text: 'ΚΟΡΝΑ', value: 'ΚΟΡΝΑ', repairDuration: 0 },
      { text: 'ΣΑΖΜΑΝ', value: 'ΣΑΖΜΑΝ', repairDuration: 0 },
      { text: 'ΛΑΔΙΑ', value: 'ΛΑΔΙΑ', repairDuration: 0 },
      { text: 'ΦΙΛΤΡΟ', value: 'ΦΙΛΤΡΟ', repairDuration: 0 },
      { text: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', value: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', repairDuration: 0 },
      { text: 'ΕΓΚΕΦΑΛΟΣ', value: 'ΕΓΚΕΦΑΛΟΣ', repairDuration: 0 },
      { text: 'ΨΥΚΤΙΚΟ', value: 'ΨΥΚΤΙΚΟ', repairDuration: 0 },
      { text: 'ΤΑΚΑΚΙΑ', value: 'ΤΑΚΑΚΙΑ', repairDuration: 0 },
      { text: 'ΜΠΟΥΖΙ', value: 'ΜΠΟΥΖΙ', repairDuration: 0 },
      { text: 'ΔΙΣΚΟΠΛΑΚΕΣ', value: 'ΔΙΣΚΟΠΛΑΚΕΣ', repairDuration: 0 },
      { text: 'ΤΑΠΑ ΚΑΡΤΕΡ', value: 'ΤΑΠΑ ΚΑΡΤΕΡ', repairDuration: 0 },
      { text: 'ΙΜΑΝΤΑΣ', value: 'ΙΜΑΝΤΑΣ', repairDuration: 0 },
      { text: 'ΜΠΑΤΑΡΙΑ', value: 'ΜΠΑΤΑΡΙΑ', repairDuration: 0 },
      { text: 'ΣΥΜΠΛΕΚΤΗΣ', value: 'ΣΥΜΠΛΕΚΤΗΣ', repairDuration: 0 },
      { text: 'ΒΟΛΑΝ', value: 'ΒΟΛΑΝ', repairDuration: 0 },
      { text: 'ΑΝΤΛΙΑ', value: 'ΑΝΤΛΙΑ', repairDuration: 0 },
      { text: 'ΚΑΛΟΡΙΦΕΡ', value: 'ΚΑΛΟΡΙΦΕΡ', repairDuration: 0 },
      { text: 'ΤΣΙΜΟΥΧΑ', value: 'ΤΣΙΜΟΥΧΑ', repairDuration: 0 },
      { text: 'ΕΛΑΤΗΡΙΟ', value: 'ΕΛΑΤΗΡΙΟ', repairDuration: 0 },
      { text: 'ΒΑΛΒΙΔΑ', value: 'ΒΑΛΒΙΔΑ', repairDuration: 0 },
      { text: 'AD BLUE', value: 'AD BLUE', repairDuration: 0 },
      {
        text: 'ΑΛΛΑΓΗ ΕΜΠ ΕΛΑΣΤΙΚΩΝ',
        value: 'ΑΛΛΑΓΗ ΕΜΠ ΕΛΑΣΤΙΚΩΝ',
        repairDuration: 0,
      },
      {
        text: 'ΑΛΛΑΓΗ ΠΙΣΩ ΕΛΑΣΤΙΚΩΝ',
        value: 'ΑΛΛΑΓΗ ΠΙΣΩ ΕΛΑΣΤΙΚΩΝ',
        repairDuration: 0,
      },
      {
        text: 'ΑΛΛΑΓΗ ΟΛΩΝ ΤΩΝ ΕΛΑΣΤΙΚΩΝ',
        value: 'ΑΛΛΑΓΗ ΟΛΩΝ ΤΩΝ ΕΛΑΣΤΙΚΩΝ',
        repairDuration: 0,
      },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Μικρό Service',
    value: 'Μικρό Service',
    details: [
      { text: 'ΛΑΔΙ', value: 'ΛΑΔΙ', repairDuration: 0 },
      { text: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', value: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Μεγάλο Service',
    value: 'Μεγάλο Service',
    details: [
      { text: 'ΛΑΔΙ', value: 'ΛΑΔΙ', repairDuration: 0 },
      { text: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', value: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', repairDuration: 0 },
      { text: 'ΦΙΛΤΡΟ ΑΕΡΟΣ', value: 'ΦΙΛΤΡΟ ΑΕΡΟΣ', repairDuration: 0 },
      { text: 'ΦΙΛΤΡΟ ΚΑΜΠΙΝΑΣ', value: 'ΦΙΛΤΡΟ ΚΑΜΠΙΝΑΣ', repairDuration: 0 },
      {
        text: 'ΦΙΛΤΡΟ ΠΕΤΡΕΛΑΙΟΥ',
        value: 'ΦΙΛΤΡΟ ΠΕΤΡΕΛΑΙΟΥ',
        repairDuration: 0,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Service A/C & Καλοριφέρ',
    value: 'Service A/C & Καλοριφέρ',
    details: [
      { text: 'SERVICE A/C', value: 'SERVICE A/C', repairDuration: 0 },
      { text: 'ΦΡΕΟΝ', value: 'ΦΡΕΟΝ', repairDuration: 0 },
      { text: 'ΚΑΛΟΡΙΦΕΡ', value: 'ΚΑΛΟΡΙΦΕΡ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Έκδοση Κάρτας Καυσαερίων',
    value: 'Έκδοση Κάρτας Καυσαερίων',
    details: [
      {
        text: 'ΕΚΔΟΣΗ ΚΑΡΤΑΣ ΚΑΥΣΑΕΡΙΩΝ',
        value: 'ΕΚΔΟΣΗ ΚΑΡΤΑΣ ΚΑΥΣΑΕΡΙΩΝ',
        repairDuration: 0,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'KTEO',
    value: 'KTEO',
    details: [{ text: 'ΚΤΕΟ', value: 'ΚΤΕΟ', repairDuration: 0 }],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Ελαστικά',
    value: 'Ελαστικά',
    details: [
      {
        text: 'ΑΛΛΑΓΗ ΕΛΑΣΤΙΚΩΝ',
        value: 'ΑΛΛΑΓΗ ΕΛΑΣΤΙΚΩΝ',
        repairDuration: 0,
      },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [],
  },
  {
    text: 'Συνδεσιμότητα',
    value: 'Συνδεσιμότητα',
    details: [
      {
        text: 'ΣΥΝΔΕΣΙΜΟΤΗΤΑ ΜΕ ΤΟ ΚΙΝΗΤΟ',
        value: 'ΣΥΝΔΕΣΙΜΟΤΗΤΑ ΜΕ ΤΟ ΚΙΝΗΤΟ',
        repairDuration: 0,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Τοποθετήσεις / Προσθήκες',
    value: 'Τοποθετήσεις / Προσθήκες',
    details: [
      { text: 'PARKTRONIC', value: 'PARKTRONIC', repairDuration: 0 },
      { text: 'ΦΗΜΕ ΤΖΑΜΙΑ', value: 'ΦΗΜΕ ΤΖΑΜΙΑ', repairDuration: 0 },
      { text: 'ΣΧΑΡΑ', value: 'ΣΧΑΡΑ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'AD Blue',
    value: 'AD Blue',
    details: [
      {
        text: 'ΠΡΟΒΛΗΜΑ ΜΕ ΣΥΣΤΗΜΑ AD BLUE',
        value: 'ΠΡΟΒΛΗΜΑ ΜΕ ΣΥΣΤΗΜΑ AD BLUE',
        repairDuration: 0,
      },
      { text: 'ΠΛΗΡΩΣΗ AD BLUE', value: 'ΠΛΗΡΩΣΗ AD BLUE', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Ανάκληση',
    value: 'Ανάκληση',
    details: [
      {
        text: 'ΜΗΧΑΝΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
        value: 'ΜΗΧΑΝΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
        repairDuration: 0,
      },
      {
        text: 'ΗΛΕΚΤΡΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
        value: 'ΗΛΕΚΤΡΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
        repairDuration: 0,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Κλοπή',
    value: 'Κλοπή',
    details: [
      { text: 'ΜΕΡΙΚΗ ΚΛΟΠΗ', value: 'ΜΕΡΙΚΗ ΚΛΟΠΗ', repairDuration: 0 },
      { text: 'ΟΛΙΚΗ ΚΛΟΠΗ', value: 'ΟΛΙΚΗ ΚΛΟΠΗ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'instadelivery',
    value: 'instadelivery',
    details: [], // empty
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Γυάλισμα',
    value: 'Γυάλισμα',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 2 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 2 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 2 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 2 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 2 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 2 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 2 },
      { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 2 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 2 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 2 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 2 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 2 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 2 },
      { text: 'ΦΑΣΑ', value: 'ΦΑΣΑ', repairDuration: 2 },
      { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 2 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Τοπική βαφή',
    value: 'Τοπική βαφή',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 2 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 2 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 2 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 2 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 2 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 2 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 2 },
      { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 2 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 2 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 2 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 2 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 2 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 2 },
      { text: 'ΦΑΣΑ', value: 'ΦΑΣΑ', repairDuration: 2 },
      { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 2 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Βαφή / Επισκευή',
    value: 'Βαφή / Επισκευή',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 6 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 6 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 6 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 6 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 6 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 6 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 6 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 6 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 6 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 6 },
      { text: 'ΤΑΠΕΤΣΑΡΙΑ', value: 'ΤΑΠΕΤΣΑΡΙΑ', repairDuration: 6 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 6 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 6 },
      { text: 'ΠΑΡΑΘΥΡΟ', value: 'ΠΑΡΑΘΥΡΟ', repairDuration: 6 },
      { text: 'ΤΖΑΜΑΚΙ', value: 'ΤΖΑΜΑΚΙ', repairDuration: 6 },
      { text: 'ΠΑΡΜΠΡΙΖ', value: 'ΠΑΡΜΠΡΙΖ', repairDuration: 6 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Κόστος Temp Car',
    value: 'Κόστος Temp Car',
    details: [],
    sideOne: [],
    sideTwo: [],
  },
];
