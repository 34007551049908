export const BILLING_PAGE_SIZE = 40;

export const BILLING_TABS = {
  instacar: {
    label: 'instacar',
    key: 'instacar',
    type: 11,
    id: 'BIC',
    statuses: {
      31: {
        label: 'Predel',
      },
      32: {
        label: 'Done Predel',
      },
      33: {
        label: 'Deactivate Predel Take Perm',
      },
      34: {
        label: 'To Be Invoiced',
      },
      35: {
        label: 'DriveHome',
      },
      36: {
        label: 'RAC',
      },
      3: {
        label: 'Done',
      },
      12: {
        label: 'Cancelled',
      },
    },
    statusOrder: [31, 32, 33, 34, 35, 36, 3, 12],
  },
  instaride: {
    label: 'instaride',
    key: 'instaride',
    type: 12,
    id: 'BIR',
    statuses: {
      3: {
        label: 'Done KINEO',
      },
      49: {
        label: 'KINEO - New Customers',
      },
      50: {
        label: 'KINEO',
      },
      51: {
        label: 'KINEO - Subscription cancelations',
      },
      52: {
        label: 'KINEO - Other',
      },
      39: {
        label: 'KINEO Invoicing Damages',
      },
      53: {
        label: 'Refunds KINEO',
      },
      54: {
        label: 'Done refunds KINEO',
      },
      12: {
        label: 'Cancelled',
      },
    },
    statusOrder: [3, 49, 50, 51, 52, 39, 53, 54, 12],
  },
  refunds: {
    label: 'Refunds',
    key: 'refunds',
    type: 13,
    id: 'BR',
    statuses: {
      53: {
        label: 'Refunds',
      },
      54: {
        label: 'Done Refunds',
      },
      12: {
        label: 'Cancelled',
      },
      3: {
        label: 'Done',
      },
    },
    statusOrder: [53, 54, 12, 3],
  },
  damages: {
    label: 'Damages',
    key: 'damages',
    type: 14,
    id: 'BD',
    statuses: {
      37: {
        label: 'Car Deliveries for everything',
      },
      38: {
        label: 'Acquittance',
      },
      39: {
        label: 'Invoicing Damages',
      },
      40: {
        label: 'Hold Damage',
      },
      41: {
        label: 'Hold Damages Complaints Indictments',
      },
      3: {
        label: 'Done',
      },
      12: {
        label: 'Cancelled',
      },
    },
    statusOrder: [37, 38, 39, 40, 41, 3, 12],
  },
  other: {
    label: 'Other',
    key: 'other',
    type: 15,
    id: 'BO',
    statuses: {
      42: {
        label: 'Credits',
      },
      43: {
        label: 'Indictments - Lawsuits',
      },
      44: {
        label: 'Continue Pricing',
      },
      45: {
        label: 'Change Period',
      },
      52: {
        label: 'LEO Other',
      },
      46: {
        label: '12M / 24M',
      },
      47: {
        label: 'Edit Pricing details',
      },
      48: {
        label: 'Ρeinsurance premium',
      },
      3: {
        label: 'Done',
      },
      12: {
        label: 'Cancelled',
      },
    },
    statusOrder: [42, 43, 44, 45, 52, 46, 47, 48, 3, 12],
  },
};

// TODO: update the custom fields
export const BILLING_TASK_FIELD_NAMES = {
  assignee: 'Assignee',
  status: 'status',
  description: 'description',
  arnmTicket: 'AR&M Ticket',
  carId: 'car id',
  pipedriveId: 'pipedrive Id',
  subscriptionId: 'subscription Id',
  title: 'title',
  userEmail: 'user Email',
  userFirstName: 'user Firstname',
  userId: 'user Id',
  userLastName: 'user Lastname',
  vehicleDescription: 'vehicle Description',
  vehicleImage: 'vehicle Image',
  vehiclePlate: 'vehicle Plate',
  vehicleVin: 'vehicle Vin',
  board_id: 'board',
};
