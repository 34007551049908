import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root-grids',
  storage,
  whitelist: ['uiSettings', 'availabilityTable'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const makeStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ['persist/PERSIST'],
        },
      }),
  });
  store.__persistor = persistStore(store);
  return store;
};

export const wrapper = createWrapper(makeStore);

export * from './logger/errorsSlice';
export * from './oauth/oauthSlice';
export * from './catalog/vehiclesSlice';
export * from './subscriptions/subscriptionsSlice';
export * from './users/usersSlice';
export * from './servicePoints/servicePointsSlice';
export * from './insurances/insurancesSlice';
export * from './bookings/bookingsSlice';
export * from './roadsideAssistance/roadsideAssistanceSlice';
export * from './serviceVehicles/serviceVehiclesSlice';
export * from './agents/agentsSlice';
export * from './subscribers/subscribersSlice';
export * from './fleetVehicles/fleetVehiclesSlice';
export * from './fleetVehicles/fleetVehicleSlice';
export * from './configuration/configurationSlice';
export * from './permissions/permissionsSlice';
export * from './availability/availabilitySlice';
export * from './returnsInbox/returnsInboxSlice';
export * from './campaigns/campaignsSlice';
export * from './notifications/notificationsSlice';
export * from './handoverCars/handoverCarsSlice';
export * from './buyout/buyOutSlice';
export * from './layout/layoutSlice';
export * from './arnmAccidents/arnmAccidentsSlice';
export * from './arnmRepairs/arnmRepairsSlice';
export * from './arnmMaintenance/arnmMaintenanceSlice';
/* PLOP_INJECT_SLICE_EXPORT */
export * from './ebike/ebikeSlice';
export * from './orders/ordersSlice';
export * from './vendors/vendorsSlice';
export * from './uiSettings/uiSettingsSlice';
export * from './fleetVehicles/addFleetVehicleSlice';
export * from './availabilityTable/availabilityTableSlice';
export * from './reservations/reservationsSlice';
export * from './greenCard/greenCardSlice';
export * from './partners/partnersSlice';
export * from './inspectionCars/inspectionCarsSlice';
export * from './trafficOffenses/trafficOffensesSlice';
export * from './trafficOffenses/trafficOffensesTaskSlice';
export * from './sellVehicles/sellVehiclesSlice';
export * from './sellRequests/sellRequestsSlice';
export * from './teams/teamsSlice';
export * from './communication/communicationSlice';
export * from './mailBoxes/mailBoxesSlice';
export * from './driverseCampaigns/driverseCampaignsSlice';
export * from './driversePartners/driversePartnersSlice';
