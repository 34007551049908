export const ENTITIES_PERMISIONS = {
  ReadBookingsGraph: 0,
  ReadBookings: 1,
  WriteBookings: 2,
  DeleteBookings: 3,
  WriteMarketingCampaigns: 4,
  DeleteMarketingCampaigns: 5,
  ReadVehicles: 6,
  WriteVehicles: 7,
  DeleteVehicles: 8,
  ImportVehicles: 9,
  ReadSubscriptions: 10,
  WriteSubscriptions: 11,
  DeleteSubscriptions: 12,
  ImportSubscriptions: 13,
  ReadServiceVehicles: 14,
  WriteServiceVehicles: 15,
  DeleteServiceVehicles: 16,
  ReadInsurances: 17,
  WriteInsurances: 18,
  DeleteInsurances: 19,
  ReadRoadsideAssistance: 20,
  WriteRoadsideAssistance: 21,
  DeleteRoadsideAssistance: 22,
  ReadServicePoints: 23,
  WriteServicePoints: 24,
  DeleteServicePoints: 25,
  ReadVehicleCatalog: 26,
  WriteVehicleCatalog: 27,
  DeleteVehicleCatalog: 28,
  ImportVehicleCatalog: 29,
  ReadUsers: 30,
  WriteUsers: 31,
  DeleteUsers: 32,
  ReadAgents: 33,
  WriteAgents: 34,
  DeleteAgents: 35,
  ReadPermissions: 36,
  WritePermissions: 37,
  DeletePermissions: 38,
  ReadCalendar: 39,
  WriteCalendar: 40,
  DeleteCalendar: 41,
  ReadConfigurations: 42,
  WriteConfigurations: 43,
  DeleteConfigurations: 44,
  WriteSearchIndex: 45,
  ReadInstapaymentReport: 46,
  ReadAvisReport: 47,
  ReadTaskBoards: 48,
  WriteTaskBoards: 49,
  DeleteTaskBoards: 50,
  ReadErrors: 51,
  UseVatFinder: 52,
  UseOCR: 53,
  SendEmailToServicePoint: 54,
  ReadOrders: 55,
  WriteOrders: 56,
  ReadDeliveryReturn: 57,
  WriteDeliveryReturn: 58,
  ReadRepairs: 59,
  WriteRepairs: 60,
  ReadMaintenance: 61,
  WriteMaintenance: 62,
  ReadAccidentsClaims: 63,
  WriteAccidentsClaims: 64,
  ReadBuyouts: 65,
  WriteBuyouts: 66,
  ReadReservations: 67,
  WriteReservations: 68,
  ReadGreenCards: 71,
  WriteGreenCards: 72,
  ReadAvailability: 69,
  WriteAvailability: 70,
  ReadPartners: 73,
  WritePartners: 74,
  ReadTrafficOffenses: 75,
  WriteTrafficOffenses: 76,
  ReadPipedriveEvents: 77,
  ReadCompetition: 78,
  ReadSaleRequests: 79,
  WriteSaleRequests: 80,
  ReadProducts: 81,
  WriteProducts: 82,
  ReadTeamsRequests: 83,
  WriteTeamsRequests: 84,
  ReadARNMReport: 85,
  ReadBillingTickets: 86,
  WriteBillingTickets: 87,
  ReadDriverse: 88,
  WriteDriverse: 89,
  ReadEmails: 90,
  WriteEmails: 91,
  ReadMailboxes: 92,
  WriteMailboxes: 93,
};

export const ENTITIES_PERMISIONS_NAMES = {
  0: 'Read Bookings Graph',
  1: 'Read Bookings',
  2: 'Write Bookings',
  3: 'Delete Bookings',
  4: 'Write Marketing Campaigns',
  5: 'Delete Marketing Campaigns',
  6: 'Read Vehicles',
  7: 'Write Vehicles',
  8: 'Delete Vehicles',
  9: 'Import Vehicles',
  10: 'Read Subscriptions',
  11: 'Write Subscriptions',
  12: 'Delete Subscriptions',
  13: 'Import Subscriptions',
  14: 'Read Service Vehicles',
  15: 'Write Service Vehicles',
  16: 'Delete Service Vehicles',
  17: 'Read Insurances',
  18: 'Write Insurances',
  19: 'Delete Insurances',
  20: 'Read Roadside Assistance',
  21: 'Write Roadside Assistance',
  22: 'Delete Roadside Assistance',
  23: 'Read Service Points',
  24: 'Write Service Points',
  25: 'Delete Service Points',
  26: 'Read Vehicle Catalog',
  27: 'Write Vehicle Catalog',
  28: 'Delete Vehicle Catalog',
  29: 'Import Vehicle Catalog',
  30: 'Read Users',
  31: 'Write Users',
  32: 'Delete Users',
  33: 'Read Agents',
  34: 'Write Agents',
  35: 'Delete Agents',
  36: 'Read Permissions',
  37: 'Write Permissions',
  38: 'Delete Permissions',
  39: 'Read Calendar',
  40: 'Write Calendar',
  41: 'Delete Calendar',
  42: 'Read Configurations',
  43: 'Write Configurations',
  44: 'Delete Configurations',
  45: 'Write Search Index',
  46: 'Read Instapayment Report',
  47: 'Read Avis Report',
  48: 'Read Task Boards',
  49: 'Write Task Boards',
  50: 'Delete Task Boards',
  51: 'Read Errors',
  52: 'Use Vat Finder',
  53: 'Use OCR',
  54: 'Send Email To Service Point',
  55: 'Read Orders',
  56: 'Write Orders',
  57: 'Read Delivery Return',
  58: 'Write Delivery Return',
  59: 'Read Repairs',
  60: 'Write Repairs',
  61: 'Read Maintenance',
  62: 'Write Maintenance',
  63: 'Read Accidents Claims',
  64: 'Write Accidents Claims',
  65: 'Read Buyouts',
  66: 'Write Buyouts',
  67: 'Read Reservations',
  68: 'Write Reservations',
  71: 'Read Green Cards',
  72: 'Write Green Cards',
  69: 'Read Availability',
  70: 'Write Availability',
  73: 'Read Partners',
  74: 'Write Partners',
  75: 'Read Traffic Offenses',
  76: 'Write Traffic Offenses',
  77: 'Read Pipedrive Events',
  78: 'Read Competition',
  79: 'Read Sale Requests',
  80: 'Write Sale Requests',
  81: 'Read Products',
  82: 'Write Products',
  83: 'Read Teams Requests',
  84: 'Write Teams Requests',
  85: 'Read ARNM Report',
  86: 'Read Billing Tickets',
  87: 'Write Billing Tickets',
  88: 'Read Driverse',
  89: 'Write Driverse',
  90: 'Read Emails',
  91: 'Write Emails',
  92: 'Read Mailboxes',
  93: 'Write Mailboxes',
};
