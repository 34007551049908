import moment from 'moment';
import { formatDate } from '@utils';
import {
  BUY_OUT_TASK_STATUSES_NAMES,
  BUY_OUT_TASK_STATUSES_SERVER_NAMES,
} from 'components/buyout/constants';
import { BOARDS_ID } from '@constants';

const _computeOfferRequestColor = (offerRequestDate) => {
  if (offerRequestDate) {
    const given = moment(offerRequestDate, 'YYYY-MM-DD');
    const current = moment().startOf('day');
    const dayDiff = moment.duration(current.diff(given)).asDays();
    if (dayDiff >= 35) {
      return '#FFA366';
    }
    return '#03AA46';
  }
  if (!offerRequestDate) {
    return '#FF0000';
  }
};

export const handleBuyOutData = (data, assignees) => {
  if (!('tasksByColumn' in data)) {
    return {};
  }
  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0]}${cur.lastName?.[0]}`.toUpperCase(),
    };
    return acc;
  }, {});

  const tasks = [];
  // eslint-disable-next-line no-unused-vars
  data?.tasksByColumn?.forEach((item, index) => {
    const assignee = assigneesMap[item.assignee] || {};

    const newTasks = item?.tasks?.map((task) => {
      return {
        ...task,
        href: `/buyout/${task?.id}`,
        status: task?.status,
        assignee,
        description: task?.name,
        createdAt: task?.createdAt
          ? formatDate(task?.createdAt, 'DD/MM/YYYY')
          : '-',
        userId: task?.customFields?.userId,
        finalPrice: task?.customFields?.finalPrice?.number
          ? new Intl.NumberFormat('el-GR', {
              style: 'currency',
              currency: 'EUR',
            }).format(task?.customFields?.finalPrice?.number)
          : '-',
        offerSentDate: task?.customFields?.offerSentDate
          ? formatDate(task?.customFields?.offerSentDate, 'DD/MM/YYYY')
          : '-',
        offerRequestDateColor: _computeOfferRequestColor(
          task?.customFields?.offerRequestDate
        ),
        approvalStatus: !task?.customFields?.approvalStatus
          ? 'Pending'
          : task?.customFields?.approvalStatus,
        loan: task?.customFields?.loan,
        loanContractId: task?.customFields?.loanContractId || '-',
      };
    });
    tasks.push(...newTasks);
  });

  return {
    boardId: BOARDS_ID?.BUY_OUT,
    tasks,
  };
};

export const handleBuyOutData2 = (data) => {
  if (!('tasksByColumn' in data)) {
    return {};
  }
  const tasks = [];
  const columns = [];
  // eslint-disable-next-line no-unused-vars
  data?.tasksByColumn?.forEach((item, index) => {
    const newTasks = item?.tasks?.map((task) => {
      return {
        ...task,
        href: `/buyout/${task?.id}`,
        status: BUY_OUT_TASK_STATUSES_NAMES[task?.status],
        assignee: item?.assignee || '-',
        createdAt: task?.createdAt
          ? formatDate(task?.createdAt, 'DD/MM/YYYY')
          : '-',
        userId: task?.customFields?.userId,
        finalPrice: task?.customFields?.finalPrice?.number
          ? new Intl.NumberFormat('el-GR', {
              style: 'currency',
              currency: 'EUR',
            }).format(task?.customFields?.finalPrice?.number)
          : '-',
        offerSentDate: task?.customFields?.offerSentDate
          ? formatDate(task?.customFields?.offerSentDate, 'DD/MM/YYYY')
          : '-',
        offerRequestDateColor: _computeOfferRequestColor(
          task?.customFields?.offerRequestDate
        ),
        approvalStatus: !task?.customFields?.approvalStatus
          ? 'Pending'
          : task?.customFields?.approvalStatus,
        loan: task?.customFields?.loan,
        loanContractId: task?.customFields?.loanContractId || '-',
      };
    });
    tasks.push(...newTasks);
  });
  data?.statuses
    ?.sort((a, b) => b.order - a.order)
    ?.reverse()
    ?.forEach((item) => {
      columns.push({
        id: item.status,
        title: BUY_OUT_TASK_STATUSES_NAMES[item.status],
        cardIds: data?.tasksByColumn
          ?.filter((ent) => {
            return (
              ent?.columnName ===
              BUY_OUT_TASK_STATUSES_SERVER_NAMES[item.status]
            );
          })
          ?.pop()
          ?.tasks?.map((task) => task?.id),
      });
    });

  return {
    boardId: data?.id,
    tasks,
    columns,
  };
};
const handleAmmountInstallmentsItem = (newAmountItem) => {
  if (newAmountItem.Key === 'amount') {
    return {
      ...newAmountItem,
      Value: newAmountItem.Value.map((amountValueItem) => ({
        ...amountValueItem,
        Value:
          amountValueItem.Key === 'number'
            ? amountValueItem.Value / 100
            : amountValueItem.Value,
      })),
    };
  }
  return newAmountItem;
};

export const handleBuyOutTaskData = (data) => {
  if (!data) {
    return {};
  }
  const changelog = data?.changelog?.reverse().map((item) => {
    const normalisedNewValue =
      item.fieldName === 'installments'
        ? item.newValue.map((newValue) =>
            newValue.map(handleAmmountInstallmentsItem)
          )
        : item.newValue;

    return { ...item, newValue: normalisedNewValue };
  });

  const task = {
    ...data,
    changelog,
    comments: data?.comments?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.userFullName || entry?.user || '-',
        comment: entry?.comment,
        edit: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY HH:mm')
          : '',
      };
    }),
    documents: data?.documents?.map((document) => {
      return { ...document, cannotDelete: document?.type === 'BuyoutOffer' };
    }),
  };
  return task;
};

export const handleVehicleData = (data) => {
  if (!data) {
    return {};
  }
  const vehicle = {
    images: data?.images,
    title: `${data?.sku?.brand || ''} ${data?.sku?.model || ''} ${
      data?.sku?.edition || ''
    } ${data?.properties?.color || ''} ${data?.year || ''} - ${
      data?.plate || ''
    }`,
    subTitle: `${data?.internalId ? `${data?.internalId} -` : '' || ''} ${
      data?.vin ? `${data?.vin} -` : ''
    } ${
      data?.procurement?.insuranceProvider
        ? `${data?.procurement?.insuranceProvider} -`
        : ''
    } ${
      data?.properties?.transmission
        ? `${data?.properties?.transmission} -`
        : ''
    } ${data?.properties?.fuelType}`,
    ownershipInfo: `${
      data?.procurement?.vehicleOwnership
        ? `${data?.procurement?.vehicleOwnership} -`
        : ''
    } ${data?.procurement?.financingProvider || ''}`,
    licenseIssuanceDate: formatDate(
      data?.procurement?.licenseIssuanceDate,
      'DD-MM-YYYY'
    ),
  };
  return vehicle;
};
